import React from "react";
import { Label } from "../styles";

const CustomLabel = ({
  name,
  label,
  required,
  sublabel = "",
  error = "",
  className = "",
}) => {
  const hasError = error?.length;
  const valueIsEmpty = label?.toString().length > 0;
  return (
    valueIsEmpty && (
      <Label
        htmlFor={name}
        className={`${className} ${hasError ? "error" : ""}`}
      >
        <span>{label}</span>
        {required && <i>*</i>}
        {sublabel && <span className="sublabel">({sublabel})</span>}
      </Label>
    )
  );
};

export default CustomLabel;
