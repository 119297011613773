import React, { useEffect, useState } from "react";
import {
  InlineMenu,
  InlineMenuItem,
  PopIconMenuItem,
  PopMenuItem,
  Tab,
  TabContainer,
  TabContents,
  TabHeader,
  Title,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetIcon } from "../../../icons";
import { HLine } from "../dashboard/styles";

const Tabs = ({ tabs = [], className = "", popupMenu = "horizontal" }) => {
  const [t] = useTranslation();
  const themeColors = useSelector((state) => state.themeColors);
  const [activeTab, setActiveTab] = useState(tabs[0]?.name);
  const [subActiveTab, setSubActiveTab] = useState(null);
  const [subActiveInlineTab, setSubActiveInlineTab] = useState(null);
  const [openedTab, setOpenedTab] = useState({});
  const [subMenus, setSubMenus] = useState(null);

  useEffect(() => {
    if (activeTab === null) {
      setActiveTab(tabs[0]?.name);
    }
  }, [tabs, activeTab]);
  return (
    <TabContainer className={popupMenu}>
      {tabs.length > 0 && (
        <TabHeader className={`sub-menu ${className} ${popupMenu}`}>
          <HLine className={popupMenu}></HLine>
          {tabs.map((tab, index) => {
            return (
              <PopIconMenuItem
                key={`${tab.name}-tab-item`}
                theme={themeColors}
                className={`${tab.name} ${
                  activeTab === tab.name && "active"
                }  ${popupMenu}`}
                onClick={() => {
                  setActiveTab(tab.name);
                  setOpenedTab((prev) => ({ ...prev, [tab.name]: true }));
                  if (tab.tabs?.length > 0) {
                    const firstMenuIndex = tab.tabs.findIndex(
                      (item) => item.type !== "title"
                    );
                    setOpenedTab((prev) => ({
                      ...prev,
                      [tab.tabs[firstMenuIndex].name]: true,
                    }));
                    setSubMenus(tab.tabs);
                    setSubActiveTab(tab.tabs[firstMenuIndex].name);
                  } else {
                    setSubActiveTab(null);
                    setSubMenus(null);
                  }
                }}
              >
                <GetIcon icon={tab.icon}></GetIcon>
                {t(tab.title)}
              </PopIconMenuItem>
            );
          })}
        </TabHeader>
      )}
      {subMenus?.length > 0 && (
        <TabHeader className={`menu ${className} ${popupMenu}`}>
          {subMenus.map((tab, index) => {
            return tab.type === "title" ? (
              <Title>
                {tab.icon && <GetIcon icon={tab.icon}></GetIcon>}
                {t(tab.title)}
              </Title>
            ) : (
              <React.Fragment>
                <PopMenuItem
                  key={`${tab.name}-sub-tab-item`}
                  theme={themeColors}
                  className={`${tab.tabs?.length > 0 ? "submenu" : ""} ${
                    subActiveTab === tab.name && "active"
                  } ${popupMenu}`}
                  onClick={() => {
                    setSubActiveTab(tab.name);
                    setOpenedTab((prev) => ({ ...prev, [tab.name]: true }));
                    if (tab.tabs?.length > 0) {
                      setSubActiveInlineTab(tab.tabs[0].name);
                    } else {
                      setSubActiveInlineTab(null);
                    }
                  }}
                >
                  {tab.icon && <GetIcon icon={tab.icon}></GetIcon>}
                  {t(tab.title)} {tab?.length}
                </PopMenuItem>
                {tab.tabs?.length > 0 && tab.name === subActiveTab && (
                  <InlineMenu>
                    {tab.tabs?.map((subTab, index) => (
                      <InlineMenuItem
                        key={`${subTab.name}-sub-tab-item-sub`}
                        theme={themeColors}
                        className={`${
                          subActiveInlineTab === subTab.name && "active"
                        } ${popupMenu}`}
                        onClick={() => {
                          setSubActiveInlineTab(subTab.name);
                          setOpenedTab((prev) => ({
                            ...prev,
                            [subTab.name]: true,
                          }));
                        }}
                      >
                        {subTab.icon && <GetIcon icon={subTab.icon}></GetIcon>}
                        {t(subTab.title)}
                      </InlineMenuItem>
                    ))}
                  </InlineMenu>
                )}
              </React.Fragment>
            );
          })}
        </TabHeader>
      )}
      {/* tab for parking process */}
      <TabContents className={`${popupMenu} ${subMenus ? "sub-menu" : "menu"}`}>
        {/* {activeTab+"/"+subActiveTab+"/"+subActiveInlineTab} */}
        {tabs.map((tab, index) => {
          return (
            <React.Fragment key={`${tab.name}-tab-content`}>
              <Tab
                className={`${className} ${popupMenu} ${tab.css ?? ""}`}
                theme={themeColors}
                active={subActiveTab === null && activeTab === tab.name}
              >
                {(openedTab[tab.name] === true || index === 0) && tab.element}
              </Tab>
              {tab.tabs?.map((subTab, index1) => (
                <React.Fragment key={`${subTab.name}-${index1}-tab-content`}>
                  <Tab
                    className={`${className} ${popupMenu} ${subTab.css ?? ""}`}
                    theme={themeColors}
                    key={`${subTab.name}-sub-tab-content`}
                    active={
                      subActiveInlineTab === null &&
                      subActiveTab === subTab.name
                    }
                  >
                    {(openedTab[subTab.name] === true || index1 === 0) &&
                      subTab.element}
                  </Tab>
                  {subTab.tabs?.map((subInlineTab, index2) => (
                    <Tab
                      className={`${className} ${popupMenu} ${
                        subInlineTab.css ?? ""
                      }`}
                      theme={themeColors}
                      key={`${subInlineTab.name}-sub-sub-tab-content`}
                      active={subActiveInlineTab === subInlineTab.name}
                    >
                      {(openedTab[subInlineTab.name] === true ||
                        index2 === 0) &&
                        subInlineTab.element}
                    </Tab>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          );
        })}
      </TabContents>
    </TabContainer>
  );
};
export default Tabs;
