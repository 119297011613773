import React, { lazy, Suspense } from "react";

// Lazy load all components
const Landing = lazy(() => import("../../public/landing"));
const Menu = lazy(() => import("../../project/pages/menu"));
const Franchise = lazy(() => import("../../project/pages/franchise"));
const Page404 = lazy(() => import("../../project/pages/page404"));
const UserType = lazy(() => import("../../project/pages/user/userType"));
const UserList = lazy(() => import("../../project/pages/user/userList"));
const Dashboard = lazy(() => import("../../project/pages/dashboard"));
const Admin = lazy(() => import("../../project/pages/franchise/admin"));
const Elements = lazy(() => import("../pages/settings/elements"));
const Settings = lazy(() => import("../../core/settings/index.js"));
const District = lazy(() => import("../../project/pages/district"));
const Area = lazy(() => import("../../project/pages/area"));
const ResultAndCertificates = lazy(() =>
  import("../../project/pages/resultAndCertificates")
);
const ExamRegistration = lazy(() =>
  import("../../project/pages/examRegistration")
);
const ExamType = lazy(() => import("../../project/pages/examType"));
const HallTicket = lazy(() => import("../../project/pages/hallTicket"));
const OldQuestionPapers = lazy(() =>
  import("../../project/pages/oldQuestionPapers")
);
const AboutUsPage = lazy(() => import("../../project/pages/abotUsPage"));
const ExamCenterRegistration = lazy(() =>
  import("../../project/pages/examCenterRegistration")
);
const CenterRegistration = lazy(() =>
  import("../../project/pages/centerRegistration")
);
const DistrictAdmin = lazy(() =>
  import("../../project/pages/district/districtAdmin.js")
);
const FloatingMenuSettings = lazy(() =>
  import("../../project/pages/floatingMenuSettings")
);
const MarkEntry = lazy(() => import("../../project/pages/markEntry"));
const ResultPublish = lazy(() => import("../../project/pages/resultPublish"));

const RenderPage = (page, key, user, privileges) => {
  const renderComponent = (Component) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component key={key} {...privileges} />
    </Suspense>
  );

  switch (page) {
    case "login":
      return renderComponent(Landing);
    case "landing":
      return renderComponent(Landing);
    case "menu":
      return renderComponent(Menu);
    case "franchise":
      return renderComponent(Franchise);
    case "user-role":
      return renderComponent(UserType);
    case "user":
      return renderComponent(UserList);
    case "dashboard":
      return renderComponent(Dashboard);
    case "admin":
      return renderComponent(Admin);
    case "elements":
      return renderComponent(Elements);
    case "settings":
      return renderComponent(Settings);

    case "district":
      return renderComponent(District);
    case "area":
      return renderComponent(Area);
    case "result-And-Certificates":
      return renderComponent(ResultAndCertificates);
    case "exam-registration":
      return renderComponent(ExamRegistration);
    case "exam-type":
      return renderComponent(ExamType);
    case "hall-ticket":
      return renderComponent(HallTicket);
    case "old-question-papers":
      return renderComponent(OldQuestionPapers);
    case "about-us":
      return renderComponent(AboutUsPage);
    case "exam-center-registration":
      return renderComponent(ExamCenterRegistration);
    case "center-registration":
      return renderComponent(CenterRegistration);
    case "district-admin":
      return renderComponent(DistrictAdmin);
    case "floating-menu-settings":
      return renderComponent(FloatingMenuSettings);
    case "mark-entry":
      return renderComponent(MarkEntry);
    case "result-publish":
      return renderComponent(ResultPublish);

    default:
      return renderComponent(Page404);
  }
};
export default RenderPage;
