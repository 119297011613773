import React from "react";
import { SearchInput } from "./styles";
import { GetIcon } from "../../../icons";

function Search({
  className = "",
  theme,
  placeholder,
  value,
  onChange,
  active = false,
}) {
  return (
    <SearchInput
      theme={theme}
      className={(value.length > 0 || active ? "active " : " ") + className}
    >
      <GetIcon icon="search" />
      <input
        name={"search"}
        type="text"
        autoComplete="false"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </SearchInput>
  );
}
export default Search;
