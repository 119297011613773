import { Container } from "../../../core/layout/styels";
import styled from "styled-components";
import "./style.css";

const FooterContent = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: #000000;
  // padding-top: 20px;
  padding: 0 50px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FooterInside = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px 0;
  align-items: flex-start;
  font-weight: normal;
  .footer-logo {
    width: 300px;
    height: auto;
    img {
      max-width: 100%;
      // filter: invert(1) grayscale(1);
    }
  }
  .social-icons {
    display: flex;
    gap: 40px;
    color: white;
    font-size: 25px;
    flex-wrap: wrap;
    justify-content: space-between;
    a {
      color: white;
      cursor: pointer;
    }
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    max-width: 20%;
    font-weight: normal;
  }
  .footer-address {
    max-width: 20%;
    color: white;
    font-weight: normal;
  }

  .footer-links p,
  .footer-address p {
    margin: 5px 0;
    color: #fff;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .footer-links {
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-bottom: 50px;
    }
    .footer-address,
    .footer-links {
      max-width: 100%;
      margin: 20px auto;
    }
    .footer-logo {
      max-width: 100%;
      height: auto;
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
      padding: 10px;
      img {
        max-width: 100%;
        width: 200px;
        // filter: invert(1) grayscale(1);
        margin: auto;
      }

      p {
        padding: 10px;
      }
    }
  }
`;

const SubscriptionForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  &.theme2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    flex-direction: column;

    &.theme2 {
      align-items: center;
      justify-content: center;
    }
  }
`;

const EmailInput = styled.input`
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
  outline: none;
  background-color: #505050;
  color: white;
  &.theme2 {
    border-radius: 5px;
  }
  @media (max-width: 768px) {
    border-radius: 5px;
  }
`;

const Footer = () => {
  return (
    <FooterContent>
      <Container style={{ display: "flex", flexDirection: "column" }}>
        <FooterInside>
          <div className="footer-logo">
            <img
              src={"/static/media/logo.cabad83f83216010b272.png"}
              alt="Logo"
            />
          </div>
          <div className="footer-links">
            <h3 style={{ color: "#fff" }}>Company</h3>
            <p>About Us</p>
            <p> Contact us</p>
            <p>Pricing</p>
            <p>Testimonials</p>
          </div>
          <div className="footer-links">
            <h3 style={{ color: "#fff" }}>Contact Us</h3>
            <p>qsconline@gmail.com</p>
            <p>7994162608</p>
          </div>
          <SubscriptionForm className={"theme2"}>
            <p style={{ color: "#fff" }}>Stay upto date</p>
            <EmailInput
              type="email"
              className="email-input theme2"
              placeholder="Your email address"
            />
          </SubscriptionForm>
        </FooterInside>
        <div className="separator"></div>
        <div className="footer-des copyright" style={{ color: "white" }}>
          © 2024 Copyright © Quran Study Centre, Kerala. All Rights Reserved
        </div>
      </Container>
    </FooterContent>
  );
};
export default Footer;
