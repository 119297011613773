import React, { useState, useEffect, useRef } from "react";
import FormInput from "../../input";
import { Footer, Form, Page, Overlay, ErrorMessage, Record } from "./styles";
import { useTranslation } from "react-i18next";
import { CloseButton } from "../popup/styles";
import { DwonlaodIcon, GetIcon } from "../../../../icons";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../manage/styles";
import { customValidations } from "../../../project/form/validation";
import Captcha from "../../captcha";
import moment from "moment";
import * as xlsx from "xlsx";
import { AddButton, ButtonPanel, FileButton, Table, Td, Tr } from "../styles";
import { addSelectObject } from "../../../../store/actions/select";
import { bulkUploadData, getData, postData } from "../../../../backend/api";
import { RowContainer } from "../../../styles/containers/styles";
import { NoBulkDataSelected } from "../nodata";
const CrudForm = React.memo((props) => {
  // Use the useTranslation hook from react-i18next to handle translations
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [selectedLanguage] = useState(i18n.language || "de");
  const [errorCount, setErrorCount] = useState(0);
  const dispatch = useDispatch();
  const { setMessage, setLoaderBox, currentApi, audioCapture = false } = props;
  const [formBulkErrors, setBulkFormErrors] = useState([]);
  // State to store the form input fields
  const [formState] = useState(props.formInput);
  // State to store the submit button's disabled status
  const [submitDisabled, setSubmitDisabled] = useState(true);

  // State to store the form values
  const [formValues, setFormValues] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const { formValues: tempFormValues } = props;
  useEffect(() => {
    if (!formValues && !lastUpdated) {
      setFormValues(JSON.parse(JSON.stringify(tempFormValues))); // Deep copy
      setLastUpdated(JSON.parse(JSON.stringify(tempFormValues))); // Deep copy
      console.log("initial change!");
    }
  }, [tempFormValues, formValues, lastUpdated]);

  //State to store Captcha Status Validations Status
  const [captchaStatus, setCaptchaStatus] = useState(false);

  // State to store the validation messages
  const [formErrors, setFormErrors] = useState(props.formErrors);
  const themeColors = useSelector((state) => state.themeColors);
  /**
   * fieldValidation is a callback function to validate a form field based on its properties
   *
   * @param {object} field - The field to be validated
   * @param {string} value - The value of the field
   *
   * @returns {number} flags - The number of validation errors for the field
   */

  // voice recorder
  const [recording, setRecording] = useState(false);
  const [fillingForm, setFillingForm] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  useEffect(() => {
    // This function will be called when the component unmounts
    return () => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state === "recording"
      ) {
        mediaRecorderRef.current.stop();
        console.log("Recording stopped due to page close/component unmount");
      }
    };
  }, []);
  const handleStartRecording = () => {
    setRecording(true);
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = handleStopRecording;
      mediaRecorderRef.current.start();
    });
  };

  const handleStopRecording = () => {
    setRecording(false);
    mediaRecorderRef.current.stop();
    // Stop all tracks to release the microphone
    mediaRecorderRef.current.stream
      .getTracks()
      .forEach((track) => track.stop());

    const audioBlob = new Blob(audioChunksRef.current, {
      type: "audio/ogg; codecs=opus",
    });

    // Convert Blob to ArrayBuffer
    const reader = new FileReader();
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      const audioFile = new File([arrayBuffer], "audio.ogg", {
        type: "audio/ogg",
        lastModified: new Date().getTime(),
      });
      console.log("Audio File size: ", audioFile.size); // Log the file size for debugging
      setAudioFile(audioFile);
    };
    reader.readAsArrayBuffer(audioBlob);
    audioChunksRef.current = [];
  };

  useEffect(() => {
    const handleSubmit = async (event) => {
      if (audioFile) {
        setFillingForm(true);
        const fields = {
          title: props.header.replace("Add a", "").trim(),
          audio: [audioFile], // Wrapping the audio file in an array to mimic FileList structure
          formValues: JSON.stringify(
            [...formState].reduce((acc, item) => {
              if (item.add) {
                acc[item.name] = ""; // Replace the value of each form field with an empty string
              }
              return acc;
            }, {})
          ),
        };
        setAudioFile(null);
        const response = await postData(fields, "user/transcribeAudio");
        if (response.status === 200) {
          console.log(response.data.output);
          let udpateValue = {
            ...formValues,
            ...Object.keys(response.data.output).reduce((acc, key) => {
              if (response.data.output[key]?.length > 0) {
                acc[key] = response.data.output[key];
              }
              return acc;
            }, {}),
          };
          setFillingForm(false);
          setFormValues(udpateValue);
        } else {
          setFillingForm(false);
          console.error("Failed to upload audio");
        }
      }
    };
    handleSubmit(audioFile);
  }, [audioFile, fillingForm, formValues, props.header, formState]);
  //---
  const catchaValidation = (captchaStatus, useCaptcha) => {
    let flag = 0;
    let tempformError = "";
    if (captchaStatus === false && useCaptcha === true) {
      tempformError = t("required", { label: t("captcha") });
      flag += 1;
    }
    return { flag, tempformError };
  };

  const validation = (
    fields,
    tempudpatedValue,
    formErrors,
    agreement,
    useCheckbox,
    useCaptcha
  ) => {
    const tempformErrors = { ...formErrors };
    const udpatedValue = { ...tempudpatedValue };
    let flags = 0;
    fields.forEach((item) => {
      if (item.name !== "_id") {
        if (item.type === "multiple") {
          item.forms.forEach((form, multipleIndex) => {
            form.forEach((inputs, index) => {
              const res = fieldValidation(
                inputs,
                typeof udpatedValue[item.name][multipleIndex][inputs.name] ===
                  "undefined"
                  ? ""
                  : udpatedValue[item.name][multipleIndex][inputs.name],
                null,
                udpatedValue
              );
              tempformErrors[item.name][multipleIndex][inputs.name] =
                res.tempformError;
              flags += res.flag; //?res.flag:0;
            });
          });
        } else if (item.validation === "greater") {
          const res = fieldValidation(
            item,
            typeof udpatedValue[item.name] === "undefined"
              ? ""
              : udpatedValue[item.name],
            typeof udpatedValue[item.reference] === "undefined"
              ? new Date()
              : udpatedValue[item.reference],
            udpatedValue
          );
          tempformErrors[item.name] = res.tempformError;
          flags += res.flag; //?res.flag:0;
        } else {
          const res = fieldValidation(
            item,
            typeof udpatedValue[item.name] === "undefined"
              ? ""
              : udpatedValue[item.name],
            null,
            udpatedValue
          );
          tempformErrors[item.name] = res.tempformError;
          flags += res.flag; //?res.flag:0;
        }
      }
    });

    const captchaRes = catchaValidation(agreement, useCaptcha);
    tempformErrors["captchaError"] = captchaRes.tempformError;
    flags += captchaRes.flag; //?res.flag:0;

    // const agreementRes = agreementValidation(agreement, useCheckbox);
    // tempformErrors["captchaError"] = agreementRes.tempformError;
    // flags += agreementRes.flag; //?res.flag:0;

    setFormErrors(tempformErrors);
    setSubmitDisabled(flags > 0 ? true : false);
    if (flags === 0) {
      return true;
    } else {
      return false;
    }
  };

  const fieldValidation = (
    field,
    value,
    ref = new Date(),
    udpatedValue = {}
  ) => {
    let flag = 0;
    let tempformError = "";

    if (!field.update && props.formType === "put") {
      return { flag, tempformError };
    }
    if (!field.add && props.formType === "post") {
      return { flag, tempformError };
    }

    if (!field.required && (value?.length ?? 0) === 0) {
      return { flag, tempformError };
    }
    if (field.condition) {
      let conditionStatus = false;
      if (Array.isArray(field.condition.if)) {
        // Code to execute if field.condition.if is an array
        conditionStatus = field.condition.if.some(
          (item) => item === udpatedValue[field.condition.item]
        );
      } else {
        // Code to execute if field.condition.if is not an array
        conditionStatus =
          udpatedValue[field.condition.item] === field.condition.if;
      }
      if (conditionStatus) {
        if (field.condition.then === "disabled") {
          return { flag, tempformError };
        }
      } else {
        if (field.condition.else === "disabled") {
          return { flag, tempformError };
        }
      }
    }
    switch (field?.validation) {
      case "email":
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if (!regex.test(value)) {
          if ((value?.length ?? 0) > 0) {
            tempformError = t("validContent", { label: t(field.label) });
          } else {
            tempformError = "";
          }
          flag += 1;
        }
        break;
      case "qt":
        const qtRegex = /^\d{8}$|^$/;
        if (!qtRegex.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "number":
        const numberRegex = /^\d+$/;
        if (
          !numberRegex.test(value) ||
          isNaN(value) ||
          value === null ||
          typeof value === "undefined"
        ) {
          if ((value?.length ?? 0) > 0) {
            tempformError = t("validContent", { label: t(field.label) });
          }
          flag += 1;
        }
        break;
      case "mobileNumber":
        const phoneRegex = new RegExp(
          `^[1-9]\\d{${(udpatedValue.PhoneNumberLength ?? 10) - 1}}$`
        );
        if (!phoneRegex.test(value)) {
          if ((value?.length ?? 0) > 0) {
            tempformError = `Please provide a valid ${
              udpatedValue.PhoneNumberLength ?? 10
            }-digit WhatsApp Number`;
          }

          flag += 1;
        }
        break;
      case "cvv":
        if (!/^[0-9]{3}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        } // German credit cards typically have a 3-digit CVV
        break;
      case "ccn":
        if (!/^[0-9]{16}$/.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        let sum = 0;
        for (let i = 0; i < (value?.length ?? 0); i++) {
          let digit = parseInt(value[i]);
          if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
              digit -= 9;
            }
          }
          sum += digit;
        }
        if (sum % 10 !== 0) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "expiry":
        if (!validateExpiry(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "true":
        if (value !== true) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "fileNumber":
        const fileNumber = /[A-Z0-9-]/;
        if (!fileNumber.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "licensePlate":
        const german = /^[A-Z]{3}[ -]?[A-Z0-9]{2}[ -]?[A-Z0-9]{3,5}$/i;
        if (!german.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "url":
        const url = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        if (!url.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "name":
        const nameRegex = /^[A-Za-z\s]+$/;
        if (!nameRegex.test(value)) {
          if ((value?.length ?? 0) > 0) {
            tempformError = "Only English characters and spaces are supported";
          }
          flag += 1;
        }
        break;
      case "slug":
        const slug = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
        if (!slug.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;

      case "greater":
        const referedDate = new Date(ref);
        if (new Date(value) < referedDate) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "amount":
        const amount = /^\d+([.,]\d{1,2})?$/;
        if (!amount.test(value)) {
          tempformError = t("validContent", { label: t(field.label) });
          flag += 1;
        }
        break;
      case "datetime":
      case "time":
        const date = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        if (!date.test(value)) {
          if ((value?.length ?? 0) > 0) {
            tempformError = t("validContent", { label: t(field.label) });
          }
          flag += 1;
        }
        break;
      case "password-match":
        const passwordMatchRegex =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@.$!%*?&]{8,}$/;
        const newPassword = udpatedValue["newPassword"];
        const confirmPassword = udpatedValue["confirmPassword"];
        if (newPassword !== confirmPassword) {
          tempformError = "Passwords are not match!";
          flag += 1;
        } else {
          if (!passwordMatchRegex.test(value)) {
            tempformError = t("validContent", { label: t(field.label) });
            flag += 1;
          }
        }
        break;
      case "password":
        const passwordRegex =
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$.!%*?&]{8,}$/;
        // Explanation of the regex:
        // - At least one uppercase letter (A-Z)
        // - At least one lowercase letter (a-z)
        // - At least one digit (0-9)
        // - At least one special character (@, $, !, %, *, ?, &)
        // - Minimum length of 8 characters

        if (!passwordRegex.test(value)) {
          if ((value?.length ?? 0) > 0) {
            tempformError = t("validContent", { label: t(field.label) });
          }
          flag += 1;
        }
        break;
      case "text":
        break;
      default:
        break;
    }
    const customStatus = customValidations(
      field,
      tempformError,
      value,
      flag,
      t
    );
    tempformError = customStatus.tempformError;
    flag = customStatus.flag;

    if (
      (field.type === "image" || field.type === "file") &&
      props.formType === "post"
    ) {
      if ((value?.length ?? 0) === 0) {
        tempformError = t("validImage", { label: t(field.label) });
        flag += 1;
      }
    } else if (
      (field.type === "image" || field.type === "file") &&
      props.formType === "put"
    ) {
      return { flag, tempformError };
    } else if (field.type === "checkbox") {
      return { flag, tempformError };
    } else {
      if (field.required && (value?.length ?? 0) === 0) {
        tempformError = t("required", { label: t(field.label) });
        console.log(field.label, field.type, { field });
        flag += 1;
      } else if ((field.minimum ?? 0) > (value?.length ?? 0)) {
        tempformError = t("requiredMinimum", {
          minimum: field.minimum ?? 0,
          label: t(field.label),
        });

        flag += 1;
      } else if ((field.maximum ?? 2000) < (value?.length ?? 0)) {
        tempformError = t("maxLimit", {
          maximum: field.maximum ?? 2000,
          label: t(field.label),
        });
        flag += 1;
      }
    }
    return { flag, tempformError };
  };
  function validateExpiry(expiry) {
    let month = parseInt(expiry.substring(0, 2));
    let year = parseInt("20" + expiry.substring(3));
    let now = new Date();
    let currentYear = now.getFullYear();
    let currentMonth = now.getMonth() + 1; // JavaScript months are 0-11
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return false; // Expiry date is in the past
    }
    if (month < 1 || month > 12) {
      return false; // Invalid month
    }
    return true;
  }

  useEffect(() => {}, [formState]);

  // const agreementValidation = (agreement, useCheckbox) => {
  //   let flag = 0;
  //   let tempformError = "";
  //   if (agreement !== true && useCheckbox === true) {
  //     tempformError = t("required", { label: t("agreement") });
  //     flag += 1;
  //   }
  //   return { flag, tempformError };
  // };
  const handleBulkChange = (
    sl = 0,
    event,
    id,
    type = "text",
    sub = null,
    data = {}
  ) => {
    data = jsonData[sl];
    console.log(data);
    const fullData = [...jsonData];
    const field = formState[id];
    let value = "";
    if (type === "checkbox") {
      value = event;
    } else if (type === "select") {
      value = event.id;
    } else if (type === "multiSelect") {
      const items = data[field.name];
      const index = items.findIndex((item) => item === event.id);

      if (index === -1) {
        // If event._id doesn't exist, push it to the items array
        items.push(event.id);
      } else {
        // If event._id already exists, remove it from the items array
        items.splice(index, 1);
      }
      value = items;
    } else if (
      type === "text" ||
      type === "number" ||
      type === "password" ||
      type === "color"
    ) {
      value = event.target.value;
    } else if (type === "search") {
      value = JSON.stringify(event);
    } else if (type === "image" || type === "file") {
      value = event.target.files;
    } else if (type === "datetime" || type === "time") {
      if (event) {
        value = event.toISOString();
      } else {
        value = "";
      }
    } else if (type === "date") {
      if (event) {
        event.setHours(14, 0, 0, 0); // Set time to 14:00 (2:00 PM)
        value = event.toISOString();
      } else {
        value = "";
      }
    } else {
      value = event.target.getAttribute("value");
    }

    const udpateValue = {
      ...data,
      [field.name]: value,
    };
    fullData[sl] = udpateValue;
    setJsonData(fullData);
    let isValidate = 0;
    const formErrorTemp = [...formBulkErrors];
    fullData.map((data, index) => {
      const errorItem = validation(
        formState,
        data,
        formErrorTemp[index],
        false,
        false,
        sl
      );
      formErrorTemp[index] = errorItem.tempformErrors;
      isValidate += errorItem.flags;
      return true;
    });
    setBulkFormErrors(formErrorTemp);
    setErrorCount(isValidate);
    if (isValidate > 0) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
    return isValidate;
  };
  // bulk uplaod format
  const [jsonData, setJsonData] = useState(null);
  const uploadData = async (event) => {
    setLoaderBox(true);
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const errorData = [];
        const data = new Uint8Array(e.target.result);
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        const selectData = {};
        console.log(json);
        const finalData = await Promise.all(
          json.map(async (item, itemIndex) => {
            const formErrorItem = {};
            const temp = {};
            let date = new Date();

            await Promise.all(
              formState.map(async (attribute) => {
                if (attribute.add) {
                  const itemValue = item[attribute.label];
                  let val = "";

                  if (attribute.type === "checkbox") {
                    let bool = JSON.parse(
                      attribute.default === "false" ||
                        attribute.default === "true"
                        ? attribute.default
                        : "false"
                    );
                    val = bool;
                  } else if (attribute.type === "multiSelect") {
                    val = [];
                  } else if (attribute.type === "text") {
                    val = "";
                  } else if (
                    attribute.type === "datetime" ||
                    attribute.type === "date" ||
                    attribute.type === "time"
                  ) {
                    if (attribute.default === "0") {
                      // Set hour to 00:00
                      date.setUTCHours(0, 0, 0, 0);
                      val = date.toISOString();
                    } else if (attribute.default === "1") {
                      // Set hour to 23:59
                      date.setUTCHours(23, 59, 0, 0);
                      val = date.toISOString();
                    } else {
                      // Set hour to current time
                      val = date.toISOString();
                    }
                    if (attribute.add) {
                      val =
                        attribute.default === "empty" ? "" : date.toISOString();
                    }
                  } else if (
                    attribute.type === "image" ||
                    attribute.type === "file"
                  ) {
                    if (attribute.add) {
                      val = "";
                    }
                    val = "";
                  } else {
                    val = attribute.default ?? "";
                    if (attribute.type === "select") {
                      val = attribute.default ?? "";
                    }
                  }
                  temp[attribute.name] = val ?? "";
                  if (attribute.add) {
                    temp[attribute.name] = itemValue ?? val ?? "";
                    formErrorItem[attribute.name] = "";
                    if (attribute.apiType === "API") {
                      if (!selectData[attribute.selectApi]) {
                        const response = await getData(
                          {},
                          `${attribute.selectApi}`
                        );
                        selectData[attribute.selectApi] = response.data;
                        if (response.status === 200) {
                          dispatch(
                            addSelectObject(response.data, attribute.selectApi)
                          );
                        }
                      }
                      const name = attribute.displayValue
                        ? attribute.displayValue
                        : attribute.showItem === "locale"
                        ? selectedLanguage
                        : "value";
                      const foundItem = selectData[attribute.selectApi].find(
                        (option) => option[name] === itemValue
                      );
                      if (foundItem) {
                        temp[attribute.name] = foundItem.id;
                      }
                    }
                  }
                }
              })
            );
            errorData.push(formErrorItem);
            return temp;
          })
        );

        let isValidate = 0;
        finalData.forEach((data, index) => {
          const errorItem = validation(
            formState,
            data,
            errorData[index],
            false,
            false,
            index
          );
          errorData[index] = errorItem.tempformErrors;
          isValidate += errorItem.flags;
        });
        console.log(finalData);
        console.log(errorData);
        setErrorCount(isValidate);
        setSubmitDisabled(isValidate > 0);
        setBulkFormErrors(errorData);
        setJsonData(finalData);
        setLoaderBox(false);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const bulkUplaodFormat = () => {
    const excelData = [];

    const excelRow = {};
    formState.forEach((attribute) => {
      if (attribute.add) {
        excelRow[attribute.label] = "";
      }
    });
    excelData.push(excelRow);
    const worksheet = xlsx.utils.json_to_sheet(excelData);

    // Create workbook
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, t("report"));
    // Convert workbook to Excel binary and download the file
    xlsx.writeFile(workbook, props.shortName + "-template.xlsx");
  };
  const bulkUploadHandler = async (event) => {
    setLoaderBox(true);
    await bulkUploadData(
      { data: JSON.stringify(jsonData) },
      `${currentApi}/bulk-upload`
    )
      .then((response) => {
        if (response.status === 200) {
          setJsonData(response.data.alreadyExist);
          setMessage({
            type: 1,
            content: t("bulkUploadMessage", {
              exist: response.data.alreadyExist.length,
              added: response.data.added.length,
            }),
            proceed: "Okay",
          });
          if (response.data.alreadyExist.length === 0) {
            submitChange(event);
          }
        } else if (response.status === 404) {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        } else {
          setMessage({ type: 1, content: t(response.data), proceed: "Okay" });
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        alert(error);
        setLoaderBox(false);
      });
  };
  useEffect(() => {
    console.log("last udpated changed", lastUpdated);
  }, [lastUpdated]);

  const handleChange = (
    event,
    id,
    type = "text",
    sub = null,
    country = null
  ) => {
    // Getting current field

    const field = formState[id];
    const tempFormValues = { ...formValues };
    if (sub === null) {
      let value = "";
      if (type === "checkbox" || type === "htmleditor") {
        // console.log(event)
        value = event;
      } else if (type === "select") {
        value = event.id;
        if ((field.arrayOut ?? false) === true && type !== "multiSelect") {
          tempFormValues[field.name + "Array"] = event;
        }
      } else if (type === "multiSelect") {
        if ((field.arrayOut ?? false) === true) {
          let items = tempFormValues[field.name + "Array"];
          if (!items) {
            items = [];
          }
          const index = items.findIndex((item) => item.id === event.id);

          if (index === -1) {
            // If event._id doesn't exist, push it to the items array
            items.push(event);
          } else {
            // If event._id already exists, remove it from the items array
            items.splice(index, 1);
          }
          tempFormValues[field.name + "Array"] = items;
        }
        const items = tempFormValues[field.name];
        const index = items.findIndex((item) => item === event.id);

        if (index === -1) {
          // If event._id doesn't exist, push it to the items array
          items.push(event.id);
        } else {
          // If event._id already exists, remove it from the items array
          items.splice(index, 1);
        }

        value = items;
      } else if (
        type === "email" ||
        type === "text" ||
        type === "number" ||
        type === "password"
      ) {
        value = event.target.value;
      } else if (type === "mobilenumber") {
        const phoneNumberLength = country.PhoneNumberLength ?? 10;
        const trimmedValue = event.target.value.slice(0, phoneNumberLength);
        value = trimmedValue;
      } else if (type === "search") {
        value = JSON.stringify(event);
      } else if (type === "image" || type === "file") {
        value = event.target.files;
      } else if (type === "datetime" || type === "time") {
        value = event.toISOString();
      } else if (type === "date") {
        value = moment(event)
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
          .toISOString();
      } else if (type === "textarea") {
        value = event;
      } else {
        value = event.target.getAttribute("value");
      }

      if (field.format) {
        switch (field.format) {
          case "uppercase":
            value = value.toUpperCase();
            break;
          case "lowercase":
            value = value.toLowerCase();
            break;
          case "camelcase":
            value = value.replace(/\b\w/g, (char) => char.toUpperCase()); // Convert to camelCase
            break;
          case "propercase":
            value = value
              .toLowerCase()
              .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()); // Convert to Proper Case
            break;
          case "sentence":
            // value = correctCapitalization(value);
            break;
          default:
            break;
        }
      }

      let udpateValue = {
        ...tempFormValues,
        [field.name]: value,
      };
      if (type === "mobilenumber") {
        udpateValue = {
          ...tempFormValues,
          [field.name]: value,
          phoneCode: country.phoneCode,
          PhoneNumberLength: country.PhoneNumberLength,
        };
      }

      // if (["gender", "presentWeight", "userActivenessStatus", "dateOfBirth", "height", "age", "wrist", "waist", "hip", "forearm"].includes(field.name)) {
      //   updateHealthDetails(udpateValue);
      // }
      // if (["calories"].includes(field.name)) {
      //   updateCaloriDetails(udpateValue);
      // }

      // if (["proposedCalorie"].includes(field.name)) {
      //   updateDailyCaloric(udpateValue);
      // }

      if (type === "select") {
        if (field.updateFields) {
          field.updateFields?.forEach((element) => {
            udpateValue[element.id] = element.collection
              ? event[element.collection]?.[element.value]
              : event[element.value];
          });
        }
      }
      if (typeof field.onChange === "function") {
        udpateValue = field.onChange(field.name, udpateValue);
      }
      // Creating an updated field
      // updating the formm values
      setFormValues(udpateValue);
      // Validating the fields
      if (
        validation(
          formState,
          udpateValue,
          formErrors,
          captchaStatus,
          props.useCheckbox,
          props.useCaptcha
        )
      ) {
        // Here we can write any state updation
      }
    } else {
      const main = formState[sub.index];
      const field = main.forms[sub.multipleIndex][id];
      const udpateValue = { ...tempFormValues };
      udpateValue[main.name][sub.multipleIndex][field.name] =
        event.target.value;

      setFormValues(udpateValue);
      // Validating the fields
      if (validation(formState, udpateValue, formErrors)) {
        // Here we can write any state updation
      }
    }
  };
  const setCaptchaStatusHandler = (status) => {
    setCaptchaStatus(status);
    validation(
      formState,
      formValues,
      formErrors,
      status,
      props.useCheckbox,
      props.useCaptcha
    );
  };
  const submitChange = async (event) => {
    console.log(formValues, formValues);
    event.preventDefault();
    if (
      validation(
        formState,
        formValues,
        formErrors,
        captchaStatus,
        props.useCheckbox,
        props.useCaptcha
      )
    ) {
      if (await props.submitHandler(formValues, formState, lastUpdated)) {
        setLastUpdated({ ...formValues });
        setSubmitDisabled(true);
      }
    }
  };
  const closeModal = () => {
    props.isOpenHandler(false);
  };
  // const discardChanges = () => {
  //   setFormValues(lastUpdated);
  //   setSubmitDisabled(true);
  // };
  return (
    formValues && (
      <Overlay
        key={props.referenceId}
        className={`form-container ${props.css ?? ""}`}
      >
        <Page
          className={`${props.css ?? ""} ${props.formMode ?? "single"} ${
            props.bulkUpload ? "bulk" : ""
          }`}
        >
          <Header className={`${props.css ?? ""} form`}>
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: props.header ? props.header : "Login",
                }}
              ></span>
              {(props.css ?? "") === "" && (
                <CloseButton theme={themeColors} onClick={closeModal}>
                  <GetIcon icon={"Close"} />
                </CloseButton>
              )}
            </div>
            <span>{props.description}</span>
          </Header>

          {props.bulkUpload ? (
            <Form className="list bulk" action="#">
              {jsonData?.length > 0 && (
                <ButtonPanel>
                  <AddButton onClick={() => bulkUplaodFormat()}>
                    <DwonlaodIcon></DwonlaodIcon>
                    <span>{t("Download Template")}</span>
                  </AddButton>
                  <FileButton
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={uploadData}
                  ></FileButton>
                </ButtonPanel>
              )}
              <RowContainer className=" bulk">
                {jsonData?.length > 0 ? (
                  <Table className="small">
                    {jsonData?.map((data, rowIndex) => (
                      <Tr
                        key={`${props.shortName}-${rowIndex}-${rowIndex}`}
                        className={"no-border bulk"}
                      >
                        {formState &&
                          formState.map((attribute, index) => {
                            const itemValue = data[attribute.name];
                            if (attribute.upload ?? false) {
                            } // ? data[t(attribute.label)] : data[t(attribute.label, { lng: selectedLanguage === "en" ? "de" : "en" })];
                            return (attribute.add ?? false) === true &&
                              attribute.type !== "hidden" ? (
                              <Td className="bulk" key={index}>
                                {/* <div>s */}
                                {/* {itemValue} */}
                                {/* {errorValue?.length > 0 && <div>{errorValue}</div>} */}
                                <FormInput
                                  bulkUpload={true}
                                  formValues={formValues}
                                  updateValue={{}}
                                  dynamicClass={"textarea"}
                                  placeholder={attribute.placeholder}
                                  key={`input` + index}
                                  id={index}
                                  index={rowIndex}
                                  error={formErrors[attribute.name]}
                                  value={itemValue}
                                  {...attribute}
                                  onChange={handleBulkChange}
                                />
                                {formBulkErrors[rowIndex]?.[attribute.name] && (
                                  <p>
                                    {formBulkErrors[rowIndex][attribute.name]}
                                  </p>
                                )}
                                {/* </div> */}
                              </Td>
                            ) : null;
                          })}
                      </Tr>
                    ))}
                  </Table>
                ) : (
                  <NoBulkDataSelected
                    upload={uploadData}
                    download={bulkUplaodFormat}
                    icon={props.icon}
                    shortName={props.shortName}
                  ></NoBulkDataSelected>
                )}
                {errorCount > 0 && (
                  <ErrorMessage style={{ marginTop: "10px" }}>
                    {t("errorCount", { count: errorCount })}
                  </ErrorMessage>
                )}
              </RowContainer>
            </Form>
          ) : (
            <React.Fragment>
              <Form
                action="#"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className={`${props.css ?? ""} ${props.formMode ?? "single"}`}
              >
                {/* <FormInput type="info" content="All fields marked with (*) are mandatory! "></FormInput> */}
                {audioCapture && fillingForm === true ? (
                  <Record>Filling your form..</Record>
                ) : (
                  audioCapture && (
                    <Record
                      className={`record-button ${recording && "recording"}`}
                    >
                      <button
                        id="record-button"
                        className={`record-button`}
                        onClick={
                          recording ? handleStopRecording : handleStartRecording
                        }
                      >
                        {recording ? (
                          <>
                            <GetIcon icon={"mic"}></GetIcon> Stop Recording
                          </>
                        ) : (
                          <>
                            <GetIcon icon={"mic"}></GetIcon> Record Audio
                          </>
                        )}
                      </button>
                      {recording && (
                        <p>
                          Tell us about the{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: props.header.replace("Add a", "").trim(),
                            }}
                          />{" "}
                          you want to add. For example,{" "}
                          {
                            <span>
                              {formState
                                .filter(
                                  (item) =>
                                    item.add &&
                                    ![
                                      "select",
                                      "multiSelect",
                                      "file",
                                      "image",
                                    ].includes(item.type)
                                )
                                .map((item) => item.label)
                                .join(", ")}
                            </span>
                          }
                          . You can speak in any language.
                        </p>
                      )}
                      <span className="info">
                        Tired of Typing? Just talk and AI will do the rest!
                      </span>
                    </Record>
                  )
                )}
                {formState?.length > 0 &&
                  formState.map((item, index) => {
                    let dynamicClass = "";
                    let disabled = false;
                    if (item.condition) {
                      let conditionStatus = false;
                      if (Array.isArray(item.condition.if)) {
                        // Code to execute if field.condition.if is an array
                        conditionStatus = item.condition.if.some(
                          (checkitem) =>
                            checkitem === formValues[item.condition.item]
                        );
                      } else {
                        // Code to execute if field.condition.if is not an array
                        conditionStatus =
                          formValues[item.condition.item] === item.condition.if;
                      }
                      if (conditionStatus) {
                        dynamicClass = item.condition.then;
                      } else {
                        dynamicClass = item.condition.else;
                      }
                    }
                    if (props.formType === "put" && !item.update && item.view) {
                      disabled = true;
                    }
                    let updateValue = {};
                    if (item.type === "select" || item.type === "multiSelect") {
                      if (Array.isArray(item.updateOn)) {
                        updateValue = {};
                        item.updateOn?.forEach((itemName) => {
                          updateValue[itemName] = formValues[itemName];
                        });
                      } else {
                        updateValue = {
                          [item.updateOn]: formValues[item.updateOn],
                        };
                      }
                    }
                    const params = [
                      ...(item.params ?? []),
                      props.referenceId
                        ? {
                            name: props.parentReference,
                            value: props.referenceId,
                          }
                        : {},
                    ];

                    if (
                      (props.formType === "put" &&
                        (item.update || item.view)) ||
                      (props.formType === "post" && item.add)
                    ) {
                      // console.log(item.label);
                      return (
                        <FormInput
                          disabled={disabled}
                          dynamicClass={dynamicClass}
                          formValues={formValues}
                          updateValue={updateValue}
                          placeholder={item.placeHolder}
                          key={`input` + index}
                          id={index}
                          error={formErrors[formState[index].name]}
                          value={formValues[formState[index].name]}
                          {...item}
                          params={params}
                          onChange={handleChange}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                {props.useCaptcha === true && (
                  <Captcha
                    error={formErrors["captchaError"]}
                    label={t("captcha")}
                    key="1"
                    setCaptchaStatus={setCaptchaStatusHandler}
                  ></Captcha>
                )}
              </Form>
            </React.Fragment>
          )}

          <Footer
            className={`${props.css ?? ""} ${submitDisabled ? "disabled" : ""}`}
          >
            {(props.css ?? "") === "" && (
              <FormInput type="close" value={"Cancel"} onChange={closeModal} />
            )}
            {/* {props.formType.toLowerCase() === "put" && !props.bulkUpload && <FormInput type="close" value={"Discard Changes"} onChange={discardChanges} />} */}
            <FormInput
              css={props.css}
              disabled={submitDisabled}
              type="submit"
              name="submit"
              value={props.button ? props.button : "Submit"}
              onChange={props.bulkUpload ? bulkUploadHandler : submitChange}
            />
          </Footer>
        </Page>
      </Overlay>
    )
  );
});

export default CrudForm;
