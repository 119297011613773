import React, { useState } from "react";
import styled from "styled-components";
import { getData } from "../../../backend/api";
import { useEffect } from "react";
import withLayout from "../layout";
import Header from "./Header";
import Footer from "./footer/footer";

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 16px;
  color: #1a4993;
  // padding: 10px;
  margin-top: 20px;
  width: 85%;
`;

const StyledButton = styled.button`
  background-image: linear-gradient(#fcfcfc, #f9f9f9 50%, #e9e9e9 50%, #fcfcfc);
  border: 1px solid #ddd;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 80px;
  cursor: pointer;
  margin: 3px 3px;

  &:hover {
    background-image: linear-gradient(
      to bottom,
      #f5f2f2 50%,
      #fff 50%
    ); /* Gradient colors on hover */
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d2d2;
  width: 85%;
  border-radius: 3px;
  margin: 20px 0;
  padding-top: 30px;
  padding-bottom: 150px;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  color: #005ca3;
  font-weight: 800;
  transition: color 0.3s;
  font-size: 16px;

  &:hover {
    color: brown;
  }

  p {
    margin: 5px;
  }
`;

const Arrow = styled.span`
  margin-right: 10px;
`;

const TabBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
  margin-top: 10px;
`;

const QuestionPapersComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    getData({}, "about-us").then((res) => {
      setImage(res.data.response[0].image);
     setBannerImage(res.data.response[0].landingMainbanner);
    });
  }, [image]);

  useEffect(() => {
    // Fetch data from the backend when the component mounts
    getData({}, "old-question-papers")
      .then((res) => {
        // Process the response and set the tabs state
        if (res && res.data.response) {
          // Group items with the same title (year) together
          const groupedTabs = {};
          res.data.response.forEach((item) => {
            if (groupedTabs[item.year]) {
              // If a tab with this year already exists, push the item to its items array
              groupedTabs[item.year].items.push(item);
            } else {
              // Otherwise, create a new tab with this year and add the item to its items array
              groupedTabs[item.year] = {
                year: item.year,
                items: [item],
              };
            }
          });
          // Convert the groupedTabs object into an array
          const tabsArray = Object.values(groupedTabs);
          setTabs(tabsArray);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // Run this effect only once when the component mounts

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <>
    <Header />
      <Main style={{ paddingTop: "133px" }}>
        <img
          src={process.env.REACT_APP_CDN + bannerImage}
          style={{ width: "100%", height: "auto" }}
          alt="GroupImage"
        />
        <Title>Download Question Papers Now!</Title>
        <TabBox>
          {tabs.map((tab, index) => (
            <StyledButton key={index} onClick={() => handleTabClick(index)}>
              {tab.year}
            </StyledButton>
          ))}
        </TabBox>
        <ContentBox>
          {tabs.length > 0 &&
            tabs[activeTab].items.map((item, index) => (
              <ListItem key={index}>
                <Arrow>&#11208;</Arrow>
                <a
                  href={process.env.REACT_APP_CDN + item.attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={process.env.REACT_APP_CDN + item.attachment}
                  style={{ color: "#1a4993" }}
                >
                  <p>{item.title}</p>
                </a>
              </ListItem>
            ))}
        </ContentBox>
      </Main>
      <Footer />
    </>
  );
};

export default withLayout(QuestionPapersComponent);
